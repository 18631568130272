import { FC, ReactElement, useMemo } from 'react';
import { v4 } from 'uuid';
import { ariaAttributeProps, dataAttributeProps, mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';
import CheckIcon from '../icon/CheckIcon';
import CheckMinusIcon from '../icon/CheckMinusIcon';
import Tooltip from '../Tooltip';
import { useTranslation } from 'react-i18next';

export enum SliderSize {
  M,
  S,
}

export interface CheckboxProps {
  value?: boolean;
  indeterminate?: boolean;
  label?: string | number | ReactElement;
  labelBeforeCheckbox?: boolean;
  className?: string;
  onChange?: (newValue: boolean) => void;
  disabled?: boolean;
  title?: string;
  slider?: boolean;
  sliderSize?: SliderSize;
  dot?: boolean;
  labelClass?: string;
  description?: string;
  containerClassName?: string;
  role?: string;
  stopEventPropogation?: boolean;
}

const Checkbox: FC<CheckboxProps> = (props) => {
  const {
    label = '',
    labelBeforeCheckbox,
    className,
    value,
    indeterminate,
    onChange,
    disabled,
    title,
    slider = false,
    dot = false,
    labelClass = '',
    sliderSize = SliderSize.M,
    description,
    containerClassName,
    role,
    stopEventPropogation = true,
  } = props;

  const handleClick = (): void => {
    if (disabled) return;

    const newValue = !value;
    onChange && onChange(newValue);
  };

  const margin = useMemo(() => (label ? (labelBeforeCheckbox ? `ml-3` : `mr-3`) : ''), [label, labelBeforeCheckbox]);
  const checkboxId = useMemo(() => `checkbox-${v4()}`, []);
  const { t } = useTranslation('common');

  const labelEl = useMemo(() => {
    return (
      <div className="truncate" aria-label={t('aria-label.checkbox')}>
        <Tooltip text={label} truncatedTextMode>
          {(tooltip) => (
            <div {...tooltip} data-cy="label" className="truncate">
              <span className={`${labelClass} ${description !== undefined ? 'text-black' : ''}`}>{label}</span>
            </div>
          )}
        </Tooltip>
        <Tooltip text={description} truncatedTextMode>
          {(tooltip) => (
            <div {...tooltip} className="text-color-on-first-primary-1 text-dpm-14 truncate font-light">
              {description}
            </div>
          )}
        </Tooltip>
      </div>
    );
  }, [label, description, t, labelClass]);

  return (
    <div
      role={role}
      title={title}
      {...ariaAttributeProps(props)}
      className={`my-2 ${containerClassName} ${disabled ? 'cursor-not-allowed' : ''} truncate ${
        labelBeforeCheckbox ? 'w-full' : 'w-fit'
      } max-w-full flex-shrink-0`}
      data-cy="checkbox"
      {...mouseAndKeyboardCallbackProps((e) => {
        if (!slider) {
          e.preventDefault();
        }

        if (!stopEventPropogation) {
          return;
        }
        e.stopPropagation();
      })}
    >
      {slider && (
        <label
          htmlFor={checkboxId}
          id={checkboxId + '-label'}
          className={`flex items-center ${labelBeforeCheckbox ? 'justify-between' : ''} cursor-pointer ${disabled && 'pointer-events-none'}`}
        >
          {!!labelBeforeCheckbox && labelEl}
          <div className="relative ml-2 mr-1" aria-labelledby={checkboxId + '-label'}>
            <input
              id={checkboxId}
              disabled={disabled}
              {...dataAttributeProps(props)}
              type="checkbox"
              className="sr-only"
              checked={value}
              onChange={handleClick}
              aria-labelledby={checkboxId + '-label'}
            />

            <div
              className={`toggle-label bg-gray-4 block bg-opacity-100 ${sliderSize === SliderSize.S ? 'h-5 w-10' : 'h-6 w-12'} rounded-full`}
            ></div>

            <div
              className={`check-dot border-gray-4 absolute left-0 top-0 border-[3px] bg-white bg-opacity-100 ${
                sliderSize === SliderSize.S ? 'h-5 w-5' : 'h-6 w-6'
              } rounded-full transition`}
            ></div>
          </div>
          {!labelBeforeCheckbox && labelEl}
        </label>
      )}

      {!slider && (
        <label
          className={`select-none ${labelBeforeCheckbox ? 'justify-between' : ''} flex max-w-full items-start ${
            onChange ? 'cursor-pointer' : ''
          } ${className} ${disabled && 'pointer-events-none'}`}
          {...mouseAndKeyboardCallbackProps(handleClick)}
          data-testid="checkbox-label"
          aria-label={t('aria-label.checkbox')}
          htmlFor={checkboxId}
          id={checkboxId + '-label'}
        >
          {!!labelBeforeCheckbox && labelEl}
          <input
            id={checkboxId}
            disabled={disabled}
            {...dataAttributeProps(props)}
            type="checkbox"
            className="sr-only"
            checked={value}
            onChange={handleClick}
            aria-labelledby={checkboxId + '-label'}
          />
          {!dot && (
            <div
              {...dataAttributeProps(props)}
              className={`outline-none ${
                indeterminate || value ? (disabled ? 'bg-gray-4 border-gray-4' : 'border-primary-1 bg-primary-1') : 'border-primary-1 bg-transparent'
              } rounded-dpm-sm flex items-center justify-center border-2 bg-opacity-100 ${margin} rounded-dpm-md mt-[0.128rem] h-5 w-5`}
              data-testid="checkbox-box"
              aria-labelledby={checkboxId + '-label'}
            >
              {!indeterminate && <CheckIcon data-cy="check-icon" className={`${value ? '' : 'invisible'} h-4 w-4 text-white`} />}
              {indeterminate && <CheckMinusIcon data-cy="indeterminate" className="w-4 text-white" />}
            </div>
          )}
          {dot && (
            <div
              {...dataAttributeProps(props)}
              className={`rounded-full p-2 transition-colors hover:bg-opacity-10 ${onChange ? 'cursor-pointer' : ''} ${
                !value ? 'hover:bg-gray-400' : 'hover:bg-red-500'
              }`}
              aria-labelledby={checkboxId + '-label'}
            >
              <div className={`h-2 w-2 rounded-full ${!value ? 'bg-gray-300' : 'bg-red-500'}`} />
            </div>
          )}
          {!labelBeforeCheckbox && labelEl}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
